
import { Vue, Component, Ref } from "vue-property-decorator";
import { RoleObjectDataSource } from "@/data/Object/RoleObjectDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiPen,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import { ListDataSource } from "@/data/List/ListDataSource";
import _ from "lodash";
import { IBaseConfig } from "@/ioc/types";
import { required } from "@/cms-services/consts";
@Component({
  components: { DataEditorDialog },
})
export default class Home extends Vue {
  @Ref("dataEditor") dataEditor!: DataEditorDialog;
  dataSource: RoleObjectDataSource = new RoleObjectDataSource(
    +this.$route.params.id
  );
  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 1000,
    },
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Роли",
        to: "/manage/roles/",
        exact: true,
      },
      {
        text: this.dataSource.model?.name,
        disabled: true,
      },
    ];
  }
  customMetaData: any = {
    groups: [
      {
        name: "general",
        caption: "Общие",
        priority: 1,
      },
    ],
    properties: [
      {
        name: "id",
        type: "string",
        description: "Уникальный идентификатор компонента.",
        caption: "ID",
        editor: "readonly",
        priority: 1,
        group: "general",
        visibleInTable: true,
        validations: [],
        config: {},
      },
      {
        name: "name",
        type: "string",
        description:
          'Строковое уникальное имя компонента, которое используется как ЧПУ при адресации. Уникальность обеспечивается в рамках одного класса компонентов. Например компоненты класса "Статья" и "Страница" могут иметь одинаковый ЧПУ, но двух статей с одинаковыми ЧПУ быть не должно. ',
        caption: "Системное имя",
        editor: "systemname",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 150,
            errorMessage: "Поле не может содержать больше чем 150 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может ,быть пустым",
          },
        ],
        config: {
          bindedFieldName: "normalizedName",
        },
      },
      {
        name: "normalizedName",
        type: "string",
        description:
          "Заголовок компонента. Обычно используется как основной заголовок при визуализации как страницы, так и виджетов.",
        caption: "Подпись",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: true,
        validations: [
          {
            validator: "max",
            value: 250,
            errorMessage: "Поле не может содержать больше чем 250 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может быть пустым",
          },
        ],
        config: {
          bindedFieldName: "name",
        },
      },
    ],
  };

  loaded: boolean = false;
  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: true,
    },
    {
      value: "actions",
      sortable: true,
      align: "right",
    },
  ];

  model: any = {
    userProfiles: [],
    emails: "",
    emailAddresses: [],
  };

  actions: any = {
    getUserProfiles: () => this.userProfileDataSource.items,
  };

  fields: any = [
    {
      editor: "auto-complete-select",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выберите пользователя",
        noDataText: "Данные не найдены",
        multiple: true,
      },
      name: "userProfiles",

      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
    },
    {
      name: "emails",
      editor: "memo",
      attrs: {
        outlined: true,
        label: "Введите список email адресов",
        hint: "Каждый email адрес должен начинаться с новой строки",
        persistentHint: true,
      },
    },
  ];
  $message: any;
  $confirm: any;
  toPascalCase = (str: any) => _.startCase(_.camelCase(str)).replace(/ /g, "");

  async created() {
    await Promise.all([
      this.dataSource.get(),
      this.dataSource.getUserProfiles({
        pageIndex: 1,
        pageSize: 1000,
      }),
      this.userProfileDataSource.get(),
    ]);
    this.loaded = true;
  }

  async updateOptions(value: any) {
    const config: IBaseConfig = {};
    if (value.sortBy?.length > 0) {
      config.orderFieldName = this.toPascalCase(value.sortBy?.[0] ?? "Id");
    }

    if (value.sortDesc?.length > 0) {
      config.orderFieldDirection = value.sortDesc?.[0] ? "DESC" : "ASC";
    }
    config.pageIndex = value.page;
    config.pageSize =
      value.itemsPerPage === -1 ? this.dataSource.total : value.itemsPerPage;
    await this.dataSource.getUserProfiles(config);
  }

  async add() {
    const model: any = await this.dataEditor.update(this.model);
    if (!model) return;
    try {
      model.emailAddresses = model.emails.split("\n");
      delete model.emails;
      await this.dataSource.addRole(model);
      this.$message("Роль успешно предоставлена");
    } catch (error: any) {
      this.$message(error.response.data, "error");
    }
  }

  async remove(userProfileId: number) {
    const confirm: any = await this.$confirm(
      "Вы действительно хотите отозвать эту роль у пользователя?"
    );
    if (!confirm) return;
    try {
      await this.dataSource.deleteRole(userProfileId);
      this.$message("Роль успешно отозвана");
    } catch (error: any) {
      this.$message(error.response.data, "error");
    }
  }
}
