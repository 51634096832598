import { toQueryString } from "../../cms-services/helpers";
import { IBaseConfig } from "@/ioc/types";
import axios from "axios";
import { ObjectDataSource } from "./ObjecDatatSource";

export class RoleObjectDataSource extends ObjectDataSource {
  public users: any = [];
  public total: number = 0;

  constructor(id: number) {
    super({
      className: "roles",
      config: {},
      id,
    });
  }

  public get userUrl(): string {
    return `${this.baseUrl}/userprofiles`;
  }

  public async getUserProfiles(config: IBaseConfig): Promise<any> {
    try {
      const url = `${this.userUrl}/${toQueryString(config)}`;
      const { data } = await axios.get(url);
      this.users = data.items;
      this.total = data.total;
    } catch (error) {
      throw error;
    }
  }
  public async addRole(model: any): Promise<any> {
    try {
      const { data } = await axios.post(this.userUrl, model);
      this.users = [...this.users, ...data];
    } catch (error) {
      throw error;
    }
  }

  public async deleteRole(userProfileId: number): Promise<any> {
    const index = this.users.findIndex((u: any) => u.id === userProfileId);
    if (index === -1) throw new Error("Профиль не найден");
    try {
      await axios.delete(`${this.userUrl}/${userProfileId}/`);
      this.users.splice(index, 1);
    } catch (error) {
      throw error;
    }
  }
}
